import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/mnt/fstack_dev/nykenshinkai.com/nyksk/src/components/default-mdx-layout.js";
import SEO from "../components/seo";
import Typography from "@material-ui/core/Typography";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="About Us" mdxType="SEO" />
    <h1>{`About Us`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d622a797e38e29c21894d7612535f29c/6a068/legacy-ksk.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.166666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d622a797e38e29c21894d7612535f29c/8ac56/legacy-ksk.webp 240w", "/static/d622a797e38e29c21894d7612535f29c/d3be9/legacy-ksk.webp 480w", "/static/d622a797e38e29c21894d7612535f29c/e46b2/legacy-ksk.webp 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d622a797e38e29c21894d7612535f29c/09b79/legacy-ksk.jpg 240w", "/static/d622a797e38e29c21894d7612535f29c/7cc5e/legacy-ksk.jpg 480w", "/static/d622a797e38e29c21894d7612535f29c/6a068/legacy-ksk.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d622a797e38e29c21894d7612535f29c/6a068/legacy-ksk.jpg",
              "alt": "Legacy KSK",
              "title": "Legacy KSK",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <Timeline align="alternate" mdxType="Timeline">
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        Early 1990s
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="primary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">
        Toshiki Kishi arrives in New York City and joins the NYC Kendo Club.
        <br />
        <br />
        Keiko Umemura arrives in New York City.
      </Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        March 1996
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="secondary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">
        Umemura-sensei joins NYC Kendo Club through Kishi-sensei.
      </Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        1999
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="primary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">
        Ken Kishino visits US to further his research for his Masters thesis on
        American Kendo history and meets with Kishi-sensei and Umemura-sensei.
        Kishi-sensei asked Kishino-sensei for his help in building the
        foundation for a new dojo.
      </Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        2000
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="secondary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">
        Kishino-sensei moves to New York City and joins the NYC Kendo Club.
      </Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        2001
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="primary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">
        Umemura-sensei is also recruited to plan for the new dojo.
      </Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        February 21st, 2002
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="secondary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">
        Kishi-sensei proposes “Kenshinkai” for the name of the new dojo and
        Kenshinkai, Inc. is formed.
      </Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        February 2002
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="primary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">
        The first unofficial practice of Kenshinkai is held at Dance New York
        (237 W 54th Street, NYC) as a Midtown dojo of the NYC Kendo Club. The
        people involved with this practice are Kishi-sensei, Kishino-sensei, and
        Umemura-sensei.
      </Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        March 2002
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="secondary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">
        Opening ceremony for Kenshinkai. There are no members at the other time
        other than the original three members. However, many sensei and senpai
        from other dojos come to show their support.
      </Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        July 2002
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="primary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">
        All United States Kendo Championships in Los Angeles. Three of KSK
        (Kenshinkai) members are selected to represent the AEUSKF on its men’s &
        women’s teams.
      </Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        October 7, 2002
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="secondary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">Kishi-sensei passes away.</Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        October 2002
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="primary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">
        Kishino-sensei and Umemura-sensei met at a Japanese restaurant and
        decided to continue Kenshinkai.
      </Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        June 2003
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="secondary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">
        KSK debut at the All Eastern U.S. Kendo Federation tournament.
      </Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        June 2004
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="primary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">
        Unofficial Tuesday practices are held at Dance Space Center.
      </Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        June 2005
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="secondary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">Gregory Tucker passes away.</Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        July 2005
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="primary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">
        All United States Kendo Championships in Detroit. Four of KSK members
        are selected to represent the AEUSKF on its men’s & women’s teams.
      </Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        April 25, 2006
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="secondary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">KSK officially starts its Tuesday Kendo class.</Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        May 20, 2007
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="primary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">
        New York Kenshinkai Fifth Anniversary Event Friendship & Good-will Keiko
        is held at YMCA (176 W 105th St, NYC), and Party is held at Dojo.
      </Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        February 2008
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="secondary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">
        KSK moves to Chelsea Studios (151 W 26th Street, NYC) due to
        redevelopment of the building where Dance New York is located.
      </Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        April 28, 2008
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="primary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">John O’Connor passes away.</Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        July 2008
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="secondary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">
        All United States Kendo Championships in Las Vegas. Two of KSK members
        are selected to represent the AEUSKF on its women’s team.
      </Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        April 8, 2010
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="primary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">
        The first KSK member-couple, Shiho Michishita & Jan Versoza get married.
      </Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        August 2011
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="secondary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">
        All United States Kendo Championships in Atlanta. Four of KSK members
        are selected to represent the AEUSKF on its men’s & women’s teams.
      </Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        September 23, 2011
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="primary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">
        The second KSK member-couple, Junko Inoue & Paul Winters get married.
      </Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        December 23, 2011
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="secondary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">
        The first baby girl, Suzuha Noelle Winters is born between KSK members,
        Junko Inoue-Winters and Paul Winters.
      </Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        May 11, 2012
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="primary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">
        The third KSK member-couple, Kathryn Son & Kevin Cheng get married.
      </Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        July 21, 2012 - July 22, 2012
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="secondary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">
        New York Kenshinkai Tenth Anniversary & Kishino-sensei’s Farewell Event.
      </Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        July 21, 2012
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="primary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">
        Godo Keiko at Chelsea Studios, Party at El Cantinero Restaurant.
      </Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem mdxType="TimelineItem">
    <TimelineOppositeContent mdxType="TimelineOppositeContent">
      <Typography variant="h6" style={{
            lineHeight: "inherit"
          }} mdxType="Typography">
        July 22, 2012
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator mdxType="TimelineSeparator">
      <TimelineDot color="secondary" mdxType="TimelineDot" />
      <TimelineConnector mdxType="TimelineConnector" />
    </TimelineSeparator>
    <TimelineContent mdxType="TimelineContent">
      <Typography mdxType="Typography">Picnic, BBQ at Prospect Park.</Typography>
    </TimelineContent>
  </TimelineItem>
    </Timeline>

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
  }
  
  .grvsc-code {
    display: inline-block;
    min-width: 100%;
  }
  
  .grvsc-line {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-line-highlighted {
    background-color: var(--grvsc-line-highlighted-background-color, transparent);
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, transparent);
  }
  
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      